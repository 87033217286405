import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { PostModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { cloneDeep, isEmpty, map, toNumber } from 'lodash'
import { action, computed, observable, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { recruitmentStore, RECRUITMENT_TYPES } from '@/stores/recruitment-store'
import { RecruitmentTeamCategoryModel } from '@/models/RecruitmentTeamCategoryModel'
import { RecruitmentAreaModel } from '@/models/RecruitmentAreaModel'
import { v4 as uuidv4 } from 'uuid'
import { RecruitmentHobbyModel } from '@/models/RecruitmentHobbyModel'
import { SOCIAL_SELECTORS } from '@/constants'
type RecruitmentType = 'find_my_team' | 'find_job' | 'find_hobby_buddy'

const getDefaultRecruitNo = () => {
  return {
    id: uuidv4(),
    group: undefined,
    uniqueName: undefined,
    recruitNum: undefined,
    categories: [] as any,
    selectedCategory: undefined,
  }
}

export class RecruitmentPostController {
  @observable completedUpdateType: undefined | 'create' | 'modify' | 'draft' = undefined
  @observable createPostDialog = false
  @observable isEditPost = false
  @observable postId: any = ''

  @observable createPostLoading = false
  @observable selectedPost?: PostModel = undefined
  @observable isCreatePost = true

  @observable openConfirmDiscardPostDialog = false

  @observable recruitmentTypes = RECRUITMENT_TYPES
  @observable selectedRecruitmentType: RecruitmentType = undefined as any

  @observable teamCategoriesByGroup: { [id: string]: RecruitmentTeamCategoryModel[] } = {}
  @observable areas: RecruitmentAreaModel[] = []
  @observable recruitNos = [] as any
  @observable hobbyTypes: RecruitmentHobbyModel[] = []
  @observable recruitNumber = ''
  @observable selectedHobbyId = ''
  @observable emailAddress = ''

  @observable dueDate = ''
  @observable recruitmentTitle = ''
  @observable projectArea = ''

  @observable attachFile?: File = undefined
  @observable rctTeamCategoriesLoaded = false

  @observable myDaos: CommunityModel[] = []
  @observable pinedDao?: string = undefined
  @observable enableChangePinedDao = true
  @observable uploadedFile: any = undefined

  @observable mainContent = ''
  @observable socials = []
  @observable socialSelectors = SOCIAL_SELECTORS
  @observable selectedSocial = ''
  @observable socialInfos = [] as any
  @observable isValidFile = true

  _recruimentDaoId

  constructor() {
    this._recruimentDaoId = process.env.VUE_APP_RECRUITMENT_ID as any
  }

  setupSocialInfos() {
    this.socialInfos = [this.getDefaultSocialInfo('twitter')]
  }

  @action addSocial(uniqName) {
    this.selectedSocial = ''
    if (map(this.socialInfos, 'uniqName').includes(uniqName)) return
    this.socialInfos.push(this.getDefaultSocialInfo(uniqName))
  }

  @action removeSocial(uniqName) {
    if (this.socialInfos.length === 1) return
    this.socialInfos = this.socialInfos.filter((social) => social.uniqName != uniqName)
  }

  @action getDefaultSocialInfo(uniqName, followerNumber = '', link = '') {
    const socialDetector = SOCIAL_SELECTORS.find((social) => social.uniqName === uniqName)
    return {
      uniqName: uniqName,
      link,
      followerNumber,
      url: socialDetector?.url,
      name: socialDetector?.name,
    }
  }

  @action changeAttachFile(file?: File) {
    this.uploadedFile = undefined
    this.attachFile = file
    this.isValidFile = true
  }

  @action buildRecruitmentNos(force = false) {
    const result = [] as any
    this.recruitNos.map((rctNo) => {
      const team = recruitmentStore.teamCategories.find(
        (team) => team.group === rctNo.group && team.uniqueName === rctNo.selectedCategory
      )
      if (team && (force || (toNumber(rctNo.recruitNum) && toNumber(rctNo.recruitNum) > 0)))
        result.push({ recruitNumber: rctNo.recruitNum, recruitment_team_category: team.id })
    })
    return result
  }

  @asyncAction *changeSelectedRecruitmentType(value: RecruitmentType, force = true) {
    if (force) this.clearPostData()
    if (value === 'find_my_team' && !this.teamCategoriesByGroup?.length) {
      this.teamCategoriesByGroup = yield recruitmentStore.fetchRecruitmentTeamCategories()
      this.areas = yield recruitmentStore.fetchRecruitmentAreas()
    } else if (value === 'find_hobby_buddy' && !this.hobbyTypes?.length) {
      this.selectedRecruitmentType = value
      this.hobbyTypes = yield recruitmentStore.fetchRecruitmentHobbies()
    }
    this.rctTeamCategoriesLoaded = true
    this.selectedRecruitmentType = value
  }

  @action changeRecruitNos(action: 'remove' | 'add', id = undefined) {
    if (action === 'add') this.recruitNos = [...this.recruitNos, getDefaultRecruitNo()]
    else if (action === 'remove' && id) {
      this.recruitNos = this.recruitNos.filter((item) => item.id !== id)
      this.disableTeamCategories()
    }
  }

  @action changeTeamGroup(group, recruitNoId) {
    const index = this.recruitNos.findIndex((item) => item.id === recruitNoId)
    if (index === -1) return
    this.recruitNos[index] = {
      ...this.recruitNos[index],
      group: group,
      recruitNum: undefined,
      categories: this.teamCategoriesByGroup[group],
    }
    this.disableTeamCategories()
  }

  @action changeTeamCategory(category, recruitNoId) {
    const index = this.recruitNos.findIndex((item) => item.id === recruitNoId)
    if (index === -1) return
    this.recruitNos[index] = {
      ...this.recruitNos[index],
      selectedCategory: category,
      recruitNum: undefined,
    }
    this.disableTeamCategories()
  }

  @action disableTeamCategories() {
    const disabledCategories = map(this.recruitNos, 'selectedCategory')?.filter((item) => item !== undefined)
    const target = cloneDeep(this.recruitNos).map((item) => {
      {
        const originCategories = !item.group ? [] : cloneDeep(this.teamCategoriesByGroup[item.group as any])
        const categories = originCategories?.map((category) => ({
          ...category,
          disabled: disabledCategories.includes(category.uniqueName as any),
        }))
        return {
          ...item,
          categories,
        }
      }
    })
    this.recruitNos = target
  }

  @action clearPostData() {
    this.isEditPost = false
    this.selectedPost = undefined

    // recruitment info
    this.mainContent = ''
    this.enableChangePinedDao = true
    this.pinedDao = undefined
    this.recruitNos = [getDefaultRecruitNo()]
    this.recruitmentTitle = ''
    this.selectedRecruitmentType = undefined as any
    this.dueDate = ''
    this.projectArea = ''
    this.attachFile = undefined
    this.isValidFile = true
    this.uploadedFile = undefined
    // job input
    this.emailAddress = ''
    // hobby buddy
    this.recruitNumber = ''
    this.selectedHobbyId = ''
  }

  @asyncAction *uploadJdFile() {
    if (!this.attachFile) return null
    const res = yield apiService.posts.upload(this.attachFile)
    return res?.length ? res[0].id || null : null
  }

  @action changeOpenConfirmDiscardPostDialog(value) {
    this.openConfirmDiscardPostDialog = value
  }

  @action onDiscardPost() {
    this.openConfirmDiscardPostDialog = false
    this.show(false)
  }

  @action.bound show(
    value: boolean,
    post: PostModel | undefined = undefined,
    type: undefined | 'modify' | 'draft' = undefined
  ) {
    this.createPostDialog = value
    this.fetchMyDaos()
    if (post) {
      this.clearPostData()
      this.selectedPost = post
      this.postId = post.id
      this.isEditPost = true
      this.setPostData(post)
      this.createPostDialog = true
    } else {
      this.clearPostData()
      this.changeSelectedRecruitmentType('find_my_team')
      this.setupSocialInfos()
    }
  }

  @action changePostMode(value) {
    this.isCreatePost = value
  }

  @asyncAction *fetchMyDaos() {
    recruitmentStore.fetchMyDaos()
    yield when(() => recruitmentStore.myDaosLoaded)
    this.myDaos = recruitmentStore.myDaos
  }

  @asyncAction *setRctData(rctInfo) {
    const data = rctInfo.data
    if (data) {
      const rctNoData = data.teamInfo?.length ? data.teamInfo : data.jobInfoData
      const recruitNos = [] as any
      if (rctNoData) {
        if (!this.teamCategoriesByGroup?.length) {
          this.teamCategoriesByGroup = yield recruitmentStore.fetchRecruitmentTeamCategories()
          this.areas = yield recruitmentStore.fetchRecruitmentAreas()
        }
        rctNoData.map((rctNo) => {
          const teamCate = rctNo?.recruitment_team_category
          const group = teamCate?.group
          recruitNos.push({
            id: rctNo._id,
            recruitNum: rctNo.recruitNumber,
            selectedCategory: teamCate?.uniqueName,
            group,
            categories: this.teamCategoriesByGroup[group],
          })
        })
        this.recruitNos = recruitNos
        this.disableTeamCategories()
      }
      this.emailAddress = data.emailAddress || ''
      const socialInfos = [] as any
      SOCIAL_SELECTORS.map((item) => {
        const social = data[item.uniqName]
        if (!isEmpty(social)) {
          socialInfos.push(this.getDefaultSocialInfo(item.uniqName, social.followerNumber, social.link))
        }
      })
      this.socialInfos = socialInfos
    }
    this.selectedHobbyId = rctInfo.recruitment_hobby || ''
    this.recruitNumber = rctInfo.recruitNumber || ''
  }

  @asyncAction *setPostData(post: PostModel) {
    this.mainContent = post.content || ''
    this.pinedDao = post.dao?._id
    if (this.pinedDao) this.enableChangePinedDao = false
    const rctInfo = post.recruitment_info
    this.uploadedFile = rctInfo?.jdFile
    if (rctInfo) {
      this.setRctData(rctInfo)
      this.recruitmentTitle = rctInfo.title || ''
      this.dueDate = rctInfo.dueDate || ''
      this.projectArea = rctInfo?.data?.recruitmentArea?.id
      this.changeSelectedRecruitmentType(rctInfo.type, false)
      yield when(() => this.rctTeamCategoriesLoaded)
    }
  }

  @action buidSocialParam() {
    const socialInfos = this.socialInfos.filter((social) => !isEmpty(social.link))
    const data = {} as any
    socialInfos.map((social) => {
      data[social.uniqName] = { link: social.link, followerNumber: social.followerNumber, name: social.name }
    })
    return data
  }

  @asyncAction *getPayload() {
    const rctHtmlContent = '<p>' + this.mainContent.replace(/(\r\n|\r|\n)/g, '<br>') + '</p>'
    const postParams = {
      content: this.mainContent,
      type: 'recruitment',
      status: 'public',
      data: {
        rctHtmlContent,
        content: [
          {
            type: 'text',
            htmlContent: rctHtmlContent,
            rawContent: this.mainContent,
          },
        ],
      },
    } as any

    if (this.pinedDao) {
      postParams.daoId = this.pinedDao
      postParams.daoMenu = 'recruitment'
    }

    const recruitmentInfo = {
      type: this.selectedRecruitmentType,
      title: this.recruitmentTitle.trim(),
    } as any
    if (this.isTeamType) {
      recruitmentInfo.dueDate = this.dueDate || undefined
      recruitmentInfo.recruitment_nos = this.buildRecruitmentNos()
      recruitmentInfo.recruitment_area = this.projectArea
    } else if (this.isHobbyType) {
      recruitmentInfo.recruitNumber = this.recruitNumber
      recruitmentInfo.recruitment_hobby = this.selectedHobbyId
    } else {
      recruitmentInfo.recruitment_nos = this.buildRecruitmentNos(true)
      recruitmentInfo.data = { ...this.buidSocialParam(), emailAddress: this.emailAddress }
    }
    recruitmentInfo.jdFile = this.uploadedFile ? this.uploadedFile.id : yield this.uploadJdFile()

    postParams.recruitment_info = recruitmentInfo
    return postParams
  }

  @action changeCompletedUpdateType(value) {
    this.completedUpdateType = value
  }

  @asyncAction *createPost() {
    try {
      // if (!this.attachFile && this.isJobType && !this.uploadedFile) {
      //   this.isValidFile = false
      //   return
      // }
      this.createPostLoading = true
      const payload = yield this.getPayload()
      if (this.isEditPost) {
        yield apiService.posts.updatePost({ id: this.postId, ...payload })
        this.completedUpdateType = 'modify'
        snackController.success('Update the recruitment completed')
      } else {
        yield apiService.posts.createPost(payload)
        this.completedUpdateType = 'create'
        snackController.success('Recruitment upload completed')
      }
      this.show(false)
      return true
    } catch (error) {
      snackController.commonError(error)
      return false
    } finally {
      this.createPostLoading = false
    }
  }

  @computed get isTeamType() {
    return this.selectedRecruitmentType === 'find_my_team'
  }
  @computed get isHobbyType() {
    return this.selectedRecruitmentType === 'find_hobby_buddy'
  }

  @computed get isJobType() {
    return this.selectedRecruitmentType === 'find_job'
  }

  @computed get teamGroups() {
    let other = {}
    const teamGroups = Object.keys(this.teamCategoriesByGroup)?.map((key) => {
      const item = {
        group: key,
        groupName: this.teamCategoriesByGroup[key][0].groupName,
      }
      if (key == 'other') {
        other = item
        return
      }
      return item
    }) as any
    if (other) teamGroups.push(other)
    return teamGroups
  }
}

export const recruitmentPostController = new RecruitmentPostController()
