import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { PostModel } from '@/models/post-model'
import { ProfileModel } from '@/models/profile-model'
import { RecruitmentAreaModel } from '@/models/RecruitmentAreaModel'
import { RecruitmentHobbyModel } from '@/models/RecruitmentHobbyModel'
import { RecruitmentTeamCategoryModel } from '@/models/RecruitmentTeamCategoryModel'
import { apiService } from '@/services/api-services'
import { cloneDeep } from 'lodash'
import { action, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { getFollowStatsNumberAfterSync, getPostStatsNumberAfterSync } from '@/helper/utils'
import { FollowingProfile, FollowTypeEnum, PostActionModel } from '@/stores/dispatcher'

export const RECRUITMENT_TYPES = [
  {
    name: 'Find My Team',
    uniqueName: 'find_my_team',
  },
  {
    name: 'Find Hobby Buddy',
    uniqueName: 'find_hobby_buddy',
  },
  {
    name: 'Find Job',
    uniqueName: 'find_job',
  },
]

class RecruitmentStore {
  @observable hobbyCategories: RecruitmentHobbyModel[] = []
  @observable areas: RecruitmentAreaModel[] = []
  @observable teamCategories: RecruitmentTeamCategoryModel[] = []
  @observable recruitmentDao?: CommunityModel = undefined
  @observable contributors?: ProfileModel[] = []
  @observable daoPosts: PostModel[] = []
  @observable totalDaoPosts = 0
  @observable postsLoading = false
  @observable recruitmentLoaded = false
  @observable recruitmentLoading = false
  @observable rctInfoLoaded = false
  @observable myDaos: CommunityModel[] = []
  @observable myDaosLoaded = false
  _recruitmentId
  constructor() {
    this._recruitmentId = process.env.VUE_APP_RECRUITMENT_ID as any
  }

  @asyncAction *fetchMyDaos() {
    try {
      if (this.myDaosLoaded) return
      this.myDaos = yield apiService.daos.fetchDaos()
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.myDaosLoaded = true
    }
  }

  @asyncAction *fetchPostsByFilter(query) {
    const res = yield apiService.recruitmentHandler.fetchRecruitmentPosts({ ...query, type: 'recruitment' })
    this.totalDaoPosts = res?.total || 0
    return res?.data || []
  }

  @action syncPostAction(postAction: PostActionModel) {
    this.recruitmentDao = {
      ...this.recruitmentDao,
      totalPosts: getPostStatsNumberAfterSync(this.recruitmentDao?.totalPosts, postAction.type),
    }
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (profile.type === FollowTypeEnum.dao && this._recruitmentId === profile.to) {
      this.recruitmentDao = {
        ...this.recruitmentDao,
        totalFollowers: getFollowStatsNumberAfterSync(this.recruitmentDao?.totalFollowers, profile.followState, 0),
      }
      return
    }
    if (!this.contributors?.length) return
    const userIndex = this.contributors.findIndex((item) => item._id === profile.to)
    if (userIndex !== -1) {
      this.contributors[userIndex] = {
        ...this.contributors[userIndex],
        isFollowing: profile.followState,
        loading: false,
      }
    }
  }

  @asyncAction *followUser(item: any) {
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *loadRecruitment() {
    try {
      if (this.recruitmentLoading) return
      this.recruitmentLoading = true
      this.recruitmentDao = yield apiService.daos.fetchDao(this._recruitmentId)
      this.totalDaoPosts = this.recruitmentDao?.totalPosts || 0

      this.contributors = yield apiService.daos.getContributors(this._recruitmentId)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.recruitmentLoading = true
      this.recruitmentLoaded = true
    }
  }
  @asyncAction *fetchRecruitmentHobbies() {
    try {
      this.hobbyCategories = yield apiService.recruitmentHobbyHandler.find({})
      return this.hobbyCategories
    } catch (e) {
      snackController.commonError(e)
    }
  }
  @asyncAction *fetchRecruitmentAreas(): any {
    try {
      if (!this.areas?.length) {
        const res = yield apiService.recruitmentAreaHandler.find({})
        const index = res?.findIndex((item) => item.uniqueName == 'other')
        if (index !== -1) {
          const otherArea = cloneDeep(res[index])
          res.splice(index, 1)
          res.push(otherArea)
        }
        this.areas = res
      }
      return this.areas
    } catch (e) {
      snackController.commonError(e)
    }
  }
  @asyncAction *fetchRecruitmentTeamCategories(): any {
    try {
      if (!this.teamCategories?.length) this.teamCategories = yield apiService.recruitmentTeamCategoryHandler.find({})
      const clone = cloneDeep(this.teamCategories)
      return this.buildTeamCategories(clone)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.recruitmentLoaded = true
    }
  }

  @action buildTeamCategories(arrs: RecruitmentTeamCategoryModel[]) {
    //
    const groupedCategrories = {} as any
    arrs?.map((item) => {
      if (!groupedCategrories[item.group]?.length) groupedCategrories[item.group] = [item]
      else groupedCategrories[item.group].push(item)
      return
    })
    return groupedCategrories
  }
}

export const recruitmentStore = new RecruitmentStore()
