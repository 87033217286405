import { alertController } from '@/components/alert/alert-controller'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { PostModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { async } from 'rxjs'

export class PostController {
  @observable openDeletePostDialog = false
  @observable openBlockPostOwnerDialog = false
  @observable openReportPostDialog = false
  @observable openPublicDraftDialog = false
  @observable completeUpdateType: 'block' | 'report' | 'delete' | 'public-draft' | undefined = undefined
  @observable selectedPost: PostModel | undefined = undefined
  @observable deletePostLoading = false
  @observable blockPostOwnerLoading = false
  @observable reportPostLoading = false
  @observable publicDraftLoading = false
  @observable reportReason = ''
  @observable updateBlindLoading = false
  @observable openBlindReasonDialog = false
  @observable blindReason = ''

  @observable blindReasonUsesrSide = ''
  @observable openBlindReasonDialogUserSide = false

  @observable openCompletedCreateDao = false

  constructor() {
    //
  }

  @action changeOpenCompletedCreateDao(value) {
    this.openCompletedCreateDao = value
  }

  @action changeOpenBlindReasonDialogUserSide(value, reason = '') {
    this.blindReasonUsesrSide = reason
    this.openBlindReasonDialogUserSide = value
  }

  @action changeOpenDeletePostDialog(value, postStore: PostStore | undefined = undefined) {
    this.selectedPost = postStore?.post
    this.openDeletePostDialog = value
    this.completeUpdateType = undefined
  }

  @action changeOpenBlockPostOwnerDialog(value, postStore: PostStore | undefined = undefined) {
    this.selectedPost = postStore?.post
    this.openBlockPostOwnerDialog = value
    this.completeUpdateType = undefined
  }
  @action changeOpenReportPostDialog(value, postStore: PostStore | undefined = undefined) {
    this.selectedPost = postStore?.post
    this.openReportPostDialog = value
    this.completeUpdateType = undefined
    this.reportReason = ''
  }
  @action changeOpenPublicDraftDialog(value, postStore: PostStore | undefined = undefined) {
    this.selectedPost = postStore?.post
    this.openPublicDraftDialog = value
    this.completeUpdateType = undefined
  }

  @asyncAction *deletePost() {
    if (!this.selectedPost) snackController.error('Something wrong error')
    try {
      this.deletePostLoading = true
      yield apiService.posts.deletePost(this.selectedPost?.id, this.selectedPost?.dao?._id)
      snackController.success('Delete post successfully')
      this.changeOpenDeletePostDialog(false)
      this.completeUpdateType = 'delete'
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.deletePostLoading = false
    }
  }

  @asyncAction *blockOwnerPost() {
    // if (!walletStore.verifyUserAction()) return
    if (!this.selectedPost) snackController.error('Something wrong error')
    try {
      this.blockPostOwnerLoading = true
      yield apiService.blocks.blockPostOwner(this.selectedPost?.profile?.id)
      snackController.success('Block successfully')
      this.changeOpenBlockPostOwnerDialog(false)
      this.completeUpdateType = 'block'
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.blockPostOwnerLoading = false
    }
  }
  @asyncAction *reportPost() {
    // if (!walletStore.verifyUserAction()) return
    if (!this.selectedPost) snackController.error('Something wrong error')
    try {
      this.reportPostLoading = true
      yield apiService.reports.createReport({
        postId: this.selectedPost,
        reason: this.reportReason,
      })
      snackController.success('Report successfully')
      this.changeOpenReportPostDialog(false)
      this.completeUpdateType = 'report'
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.reportPostLoading = false
    }
  }

  @asyncAction *toggleBlind(postStore: PostStore | undefined = undefined) {
    if (!postStore?.isBlind) {
      this.changeOpenBlindReasonDialog(true)
      this.selectedPost = postStore?.post
    } else {
      const res = yield alertController.confirm(
        'Cancel Blind',
        `Do you want to cancel blind this post?`,
        'Confirm',
        'Cancel'
      )
      if (res) {
        this.selectedPost = postStore.post
        yield this.updateBlindState(false)
      }
    }
  }

  @action changeOpenBlindReasonDialog(value) {
    this.blindReason = ''
    this.selectedPost = undefined
    this.openBlindReasonDialog = value
  }

  @asyncAction *updateBlindState(value) {
    try {
      this.updateBlindLoading = true
      if (!value) loadingController.increaseRequest()
      if (value && !this.isValidBlindReason) {
        snackController.error('Reason must be not empty')
        return
      }
      yield apiService.posts.updateBlindState({
        status: value,
        reason: this.blindReason,
        id: this.selectedPost?.id,
      })
      this.changeOpenBlindReasonDialog(false)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.updateBlindLoading = false
      if (!value) loadingController.decreaseRequest()
    }
  }

  @action changeBlindReason(value) {
    this.blindReason = value
  }

  @asyncAction *publicDraftPost() {
    if (!this.selectedPost) snackController.error('Something wrong error')
    try {
      this.publicDraftLoading = true
      const res = yield apiService.posts.publishDraftPost({ postId: this.selectedPost?.id })
      if (res) {
        snackController.success('Your post have published')
      }
      this.changeOpenPublicDraftDialog(false)
      this.completeUpdateType = 'public-draft'
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.publicDraftLoading = false
    }
  }

  @action changeReportReason(value) {
    this.reportReason = value
  }

  @action changeCompleteUpdateType(value) {
    this.completeUpdateType = value
  }

  @computed get isValidReason() {
    return !!this.reportReason.trim()
  }

  @computed get isValidBlindReason() {
    return !!this.blindReason.trim()
  }
}

export const postController = new PostController()
